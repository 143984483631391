import { pushDataLayer } from './dataLayer'

/**
 * This tracking can be called on product category and search result page.
 * Depending on where this function is called, the params can be different.
 * On category page there is no searchTerm and the other way around there is no
 * categoryId.
 *
 * @param {array} products Products to be tracked
 * @param {string} [categoryId] The category-ID when called on a category page
 * @param {string} [searchTerm] The search phrase when called on the search page
 */
function trackProductListView({
  products,
  categoryId = '',
  searchPhrase = '',
}) {
  if (products?.length > 0 && (categoryId || searchPhrase)) {
    const listItems = products.slice(0, 3).map((p) => p.id) || []
    const tagManagerDataLayerArgs = {
      dataLayer: {
        event: 'listView',
        ecommerce: {
          promoView: {
            listItems: listItems,
            categoryId: categoryId,
            searchTerm: searchPhrase,
          },
        },
      },
    }
    pushDataLayer(tagManagerDataLayerArgs)
  }
}

export default trackProductListView
