import React from 'react'
import MobileFilter from './MobileFilter'
import DesktopFilter from './DesktopFilter'
import { MediaResize, getAggregationToRender } from '../../../utils'

export default function ProductListFilter(props) {
  const { aggregations = {}, categoryUrls, searchData, ...rest } = props
  const aggregationsToRender = getAggregationToRender(aggregations)

  return (
    <>
      <MediaResize up="MD">
        <DesktopFilter
          aggregations={aggregationsToRender}
          categoryUrls={categoryUrls}
          searchData={searchData}
          {...rest}
        />
      </MediaResize>
      <MediaResize down="MD">
        <MobileFilter
          aggregations={aggregationsToRender}
          categoryUrls={categoryUrls}
          searchData={searchData}
          {...rest}
        />
      </MediaResize>
    </>
  )
}
