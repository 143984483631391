import React from 'react'
import { Dropdown } from '../..'
import { sortOptions, useTranslation } from '../../../utils'

export default function Sorter(props) {
  const { t } = useTranslation()
  const { queryParams = {}, totalProductCount, submitForms } = props
  const sortBy = queryParams.sortBy ?? sortOptions[0].sortBy
  const order = queryParams.order ?? sortOptions[0].order

  const dropdownOptions = sortOptions.map((o) => {
    return {
      ...o,
      label: t(o.label),
    }
  })

  const currentSorting =
    dropdownOptions.find(
      (option) => option.sortBy === sortBy && option.order === order
    ) || dropdownOptions[0]

  return (
    <>
      <span className="product-count d-none d-md-inline-block">
        {t('NUM_ARTICLES')(totalProductCount)}
      </span>
      <form className="product-list__sorter">
        <Dropdown
          id="sorter"
          name="sorting"
          options={dropdownOptions}
          value={currentSorting?.value}
          onChange={submitForms}
          anchor="right"
        />
      </form>
    </>
  )
}
