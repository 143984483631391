import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { useGlobalData, useTranslation } from '../../../../utils/providers'
import { aggregationsSortingToRender } from '../../../../utils'
import FilterResetButton from '../../ProductList/FilterResetButton'

import ActiveFilters from '../MobileFilter/ActiveFilters'
import MultiSelectFilter from './MultiSelectFilter'
//import MultiSelectFilterGrid from './MultiSelectFilterGrid'
//import RangeFilter from './RangeFilter'

const filterComponents = {
  // We officially only support the list_multiselect filter. Others kept for future reference
  list_multiselect: MultiSelectFilter,
  //list_multiselect_custom_1: MultiSelectFilterGrid,
  //range_slider: RangeFilter,
}

export default function DesktopFilter({
  aggregations = {},
  numberOfActiveFilters = 0,
  submitForms,
  resetAllFilters,
  categoryUrls,
  searchData,
}) {
  const [filterState, setFilterState] = useState([])
  const { t } = useTranslation()

  const context = useGlobalData()
  let pageChange = ''
  if (context?.pageData) {
    pageChange = context.pageData.data.id
  } else if (context?.searchResult) {
    pageChange = context.params.searchPhrase
  }

  useEffect(() => {
    setFilterState([])
  }, [pageChange])

  const toggleFilterState = async (filterId) => {
    let newFilterOpen = [...filterState]
    if (filterState.includes(filterId)) {
      // Remove from Array:
      newFilterOpen = newFilterOpen.filter((item) => item !== filterId)
      setFilterState(newFilterOpen)
    } else {
      // Add to Array:
      newFilterOpen.push(filterId)
      setFilterState(newFilterOpen)
    }
  }
  const sortedAggregationsToRender = aggregationsSortingToRender(aggregations)

  return (
    <form className="desktop-filter shadow-sm d-none d-md-block">
      <div className="categories-list-left">
        <div className="categories-list-left-heading position-relative p-3 border-bottom-1">
          <span className="d-inline-block font-size-m font-weight-semi-bold text-left">
            {t('FILTER_HEADING')}
          </span>
          <FilterResetButton
            aggregations={aggregations}
            numberOfActiveFilters={numberOfActiveFilters}
            resetAllFilters={resetAllFilters}
          />
        </div>
        <ul className="catalog_filters">
          {sortedAggregationsToRender.map((aggregation) => {
            const { key: id, type, title, min, max } = aggregation
            const Component = filterComponents[type]
            if (!Component) return null

            const filterOpen = filterState.includes(id)
            const headerClass = classNames('filter-category', {
              expanded: filterOpen,
            })

            const contentClass = classNames('filter-category', {
              'd-none': !filterOpen,
              'd-block': filterOpen,
            })

            return (
              <li key={id} className={headerClass}>
                <div
                  className="filter-category-heading font-weight-semi-bold filter-option-track"
                  title={title}
                  onClick={() => toggleFilterState(id)}
                >
                  {title}
                  {<ActiveFilters {...aggregation} />}
                </div>
                <ol className={contentClass}>
                  <Component
                    // Care: The order of properties matters here, since the aggregations
                    // have a field 'key' which could override our custom-built key below
                    // which we need for properly updating/reseting the RangeFilter
                    {...aggregation}
                    id={id}
                    key={max ? `${id}-${min}-${max}` : id}
                    submitForms={submitForms}
                    categoryUrls={categoryUrls}
                    searchData={searchData}
                  />
                </ol>
              </li>
            )
          })}
        </ul>
      </div>
    </form>
  )
}
