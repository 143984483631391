import { pushDataLayer } from './dataLayer'

/**
 * Tracks a product list filter change event and sends data to the dataLayer.
 *
 * @param {string} filterValue - The value of the selected filter.
 * @param {string[]} categoryUrls - The URLs of the selected categories.
 * @param {Object} searchData - The search data containing information about the search.
 * @param {boolean} isActive - Indicates whether the filter is active or inactive.
 */
function trackProductListFilterChange(
  filterValue,
  categoryUrls,
  searchData,
  isActive
) {
  const listFilter = {
    filterState: isActive ? 'selected' : 'deselected',
    filterValue: filterValue,
    payload: {},
  }

  if (categoryUrls?.length > 0) {
    listFilter.payload = JSON.stringify({
      categoryUrl: categoryUrls[0],
      magentoCategoryPath: categoryUrls[1],
      mCategoryPath: categoryUrls[2],
    })
  }

  if (searchData && Object.keys(searchData).length > 0) {
    listFilter.payload = JSON.stringify(searchData)
  }

  const tagManagerDataLayerArgs = {
    dataLayer: {
      event: 'listFilterClick',
      listFilter: listFilter,
    },
  }
  pushDataLayer(tagManagerDataLayerArgs)
}

export default trackProductListFilterChange
