import React from 'react'
import classNames from 'classnames'
import { useTranslation } from '../../../utils'

export default function FilterButton(props) {
  const { numberOfActiveFilters = 0, showMobileFilter } = props
  const { t } = useTranslation()

  const classes = classNames('product-list__filter-button', {
    ['product-list__filter-button--active']: numberOfActiveFilters > 0,
    ['text-center']: 'text-center',
    ['shadow-sm']: 'shadow-sm',
    ['py-3']: 'py-3',
  })

  return (
    <button type="button" className={classes} onClick={showMobileFilter}>
      <span className="text-uppercase">{t('FILTER_ACTION')}</span>
      <i className="cf-filter pl-2" />
      <ActiveFilterHint numberOfActiveFilters={numberOfActiveFilters} />
    </button>
  )
}

function ActiveFilterHint(props) {
  const { numberOfActiveFilters } = props

  if (!Number.isInteger(numberOfActiveFilters)) return null

  if (numberOfActiveFilters == 0) return null

  return (
    <span className="product-list__active-filter-hint">
      {numberOfActiveFilters}
    </span>
  )
}
