const aggregationsSortingToRender = (aggregations) => {
  const aggregationsArr = Object.values(aggregations)
    .sort((a, b) => a.position - b.position)
    .map((aggregation) => {
      const filterValues = Object.values(aggregation.values).sort(
        (a, b) => a.position - b.position
      )
      return { ...aggregation, filterValues }
    })

  return aggregationsArr
}

export default aggregationsSortingToRender
