import React from 'react'

import { Link } from '../../index'

export default function SubCategoriesList(props) {
  const { className, subCategories } = props
  return (
    <ul>
      {subCategories.map((subcategory, key) => {
        if (subcategory.include_in_sidebar === true) {
          return (
            <li key={key}>
              <Link className={className} href={subcategory.url}>
                {subcategory.name}
              </Link>
            </li>
          )
        } else {
          return null
        }
      })}
    </ul>
  )
}
