import React, { useState } from 'react'
import classNames from 'classnames'
import SubCategoriesList from './SubCategoriesList'
import { Button } from '../../index'
import { useTranslation } from '../../../utils'

export default function SubCategoriesMobile(props) {
  const [isVisible, setIsVisible] = useState(false)
  const { t } = useTranslation()
  const { subCategories } = props

  const filterListClasses = classNames('mobile-filter__list', {
    'mobile-filter__list--visible': isVisible,
  })

  return (
    <div className="mobile-filter__section">
      <button
        type="button"
        className="mobile-filter__button reset-btn"
        onClick={() => setIsVisible(true)}
      >
        {t('FILTER_LABEL_CATEGORIES')}
        <i className="cf-next" />
      </button>
      <div className={filterListClasses}>
        <div className="mobile-filter__list-header">
          <i
            onClick={() => setIsVisible(false)}
            className="cf-prev mr-3 ml-2"
          />
          <span>{t('FILTER_LABEL_CATEGORIES')}</span>
        </div>
        <SubCategoriesList
          subCategories={subCategories}
          className="form-group mobile-filter__item d-block"
        />
        <div className="mobile-filter__list-footer text-center p-3">
          <Button variant="primary" onClick={() => setIsVisible(false)}>
            {t('BACK')}
          </Button>
        </div>
      </div>
    </div>
  )
}
