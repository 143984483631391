import { determineBreakpoints } from '../../Alphapet/useMediaResize'

export default function collectFilterFormData() {
  const {
    breakpoints: { downMD: isMobile },
  } = determineBreakpoints()
  let filterForm

  filterForm = document.querySelector('.product-card-list__filter-panel')

  if (!filterForm) {
    filterForm = document.querySelector(
      isMobile ? '.mobile-filter' : '.desktop-filter'
    )
  }

  // Defensive Coding
  if (!filterForm) {
    return new FormData()
  }

  return new FormData(filterForm)
}
