import React from 'react'
import { useTranslation } from '../../../utils'
import { Button } from '../../index'

export default function FilterResetButton(props) {
  const { t } = useTranslation()
  const { numberOfActiveFilters = 0, resetAllFilters } = props
  if (numberOfActiveFilters === 0) return null

  return (
    <Button
      variant="secondary"
      btnSize="sm"
      className="product-list__filter-reset-button ml-auto"
      onClick={resetAllFilters}
    >
      {t('FILTER_RESET_ALL')}
    </Button>
  )
}
