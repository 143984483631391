import React, { Component } from 'react'
import TagManager from 'react-gtm-module'
import isEqual from 'lodash/isEqual'

import { ProductListFilter, RawHtml, SnippetContent } from '../..'
import {
  fetchRatings,
  getNumberOfActiveFilters,
  dispatchShowOverlayEvent,
  dispatchOverlayClickedEvent,
  scrollTo,
  MoreProductsProvider,
} from '../../../utils'
import { withFeatureFlags } from '../../../utils/providers'
import { trackProductListView } from '../../../utils/Alphapet/tracking'

import FilterButton from './FilterButton'
import List from './List'
import Sorter from './Sorter'
import SubCategoriesList from './SubCategoriesList'

class ProductList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobileFilterVisible: false,
      isLoading: false,
      productRatings: null,
      groupedRelations: {}, // local product data cache for 'MoreProducts'
    }
  }

  componentDidMount() {
    window.addEventListener('overlay:clicked', this.hideMobileFilter)
    this.updateRatings()
    const { products, categoryData } = this.props
    // Page info pushing to dataLayer for Makaira Trackings
    if (categoryData) {
      const tagManagerDataLayerArgs = {
        dataLayer: {
          pageType: categoryData.datatype,
          categoryTitle: categoryData.category_title,
        },
      }
      if (window.userTrackingEnabled) {
        TagManager.dataLayer(tagManagerDataLayerArgs)
      }
      trackProductListView({ products, categoryId: categoryData.id })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('overlay:clicked', this.hideMobileFilter)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.products, this.props.products)) {
      this.updateRatings()
    }
  }

  updateRatings = () => {
    if (this.props.isFeatureEnabled('product.ratings')) {
      fetchRatings(this.props.products)
        .then((results) => {
          this.setState({
            productRatings: results,
          })
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  showMobileFilter = () => {
    dispatchShowOverlayEvent()
    this.setState({ isMobileFilterVisible: true })
  }

  hideMobileFilter = () => {
    this.setState({ isMobileFilterVisible: false })
  }

  handleFormSubmit = async (options = {}) => {
    const { resetPagination = false } = options

    this.setState({ isLoading: true })
    await this.props.submitForms({ resetPagination })
    this.setState({ isLoading: false })
  }

  handleFormSubmitWithPaginationReset = () => {
    this.handleFormSubmit({ resetPagination: true })
  }

  handlePagination = () => {
    scrollTo({ id: 'body' })

    this.handleFormSubmit()
  }

  render() {
    const {
      products = [],
      aggregations = {},
      resetAllFilters,
      queryParams = {},
      totalProductCount = 0,
      categoryData = {},
      searchData = {},
    } = this.props

    if (totalProductCount < 1) {
      return <SnippetContent snippetId="empty_search_list" />
    }

    const numberOfActiveFilters = getNumberOfActiveFilters({ aggregations })
    const subCategoriesData =
      categoryData && categoryData.subcategories_data
        ? categoryData.subcategories_data
        : ''

    return (
      <MoreProductsProvider products={products}>
        <section className="container">
          <div className="row pt-3">
            <div className="col col-12 col-md-4 col-lg-3">
              {subCategoriesData.length > 0 && (
                <div className="categories-list-left shadow-sm mb-4 d-none d-md-block">
                  <div className="categories-list-left-heading position-relative p-3 border-bottom-1">
                    <span className="d-inline-block font-size-m font-weight-semi-bold text-left">
                      {categoryData.category_title}
                    </span>
                  </div>
                  <div className="p-3">
                    <SubCategoriesList subCategories={subCategoriesData} />
                  </div>
                </div>
              )}
              {Object.keys(aggregations).length > 0 && (
                <ProductListFilter
                  aggregations={aggregations}
                  numberOfActiveFilters={numberOfActiveFilters}
                  totalProductCount={totalProductCount}
                  isMobileFilterVisible={this.state.isMobileFilterVisible}
                  hideMobileFilter={dispatchOverlayClickedEvent} // for simplicity, we just simulate a click on the overlay and let the lifecycle of this component take care of everything
                  submitForms={this.handleFormSubmitWithPaginationReset}
                  resetAllFilters={resetAllFilters}
                  showMobileFilter={this.showMobileFilter}
                  subCategories={subCategoriesData}
                  categoryUrls={categoryData.url}
                  searchData={searchData}
                />
              )}
            </div>
            <div className="col col-12 col-md-8 col-lg-9">
              <div className="d-flex mb-2 mb-4 align-items-center justify-content-md-between bg-white">
                <Sorter
                  queryParams={queryParams}
                  totalProductCount={totalProductCount}
                  submitForms={this.handleFormSubmit}
                />
                {Object.keys(aggregations).length > 0 && (
                  <FilterButton
                    aggregations={aggregations}
                    numberOfActiveFilters={numberOfActiveFilters}
                    showMobileFilter={this.showMobileFilter}
                  />
                )}
              </div>
              <List
                products={products}
                queryParams={queryParams}
                totalProductCount={totalProductCount}
                submitForms={this.handlePagination}
                isLoading={this.state.isLoading}
                productRatings={this.state.productRatings}
                groupedRelations={this.state.groupedRelations}
                categoryUrls={categoryData.url}
                searchData={searchData}
                trackingData={{ origin: 'listing-page' }}
              />
              {categoryData && categoryData?.longdesc && (
                <RawHtml
                  className="mb-4"
                  element="div"
                  html={categoryData.longdesc}
                />
              )}
            </div>
          </div>
        </section>
      </MoreProductsProvider>
    )
  }
}

export default withFeatureFlags(ProductList)
export { List, MoreProductsProvider }
