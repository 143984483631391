import React from 'react'
import classNames from 'classnames'

import { useTranslation } from '../../../../utils/providers'
import { Button } from '../../../index'

import MultiSelectFilter from './MultiSelectFilter'
//import RangeFilter from './RangeFilter'

const filterComponents = {
  // We officially only support the list_multiselect filter. Others kept for future reference
  list_multiselect: MultiSelectFilter,
  //list_multiselect_custom_1: MultiSelectFilter,
  //range_slider: RangeFilter,
}

export default function MobileFilterList(props) {
  const { t } = useTranslation()
  const { isVisible = false, title, type, submitForms, closeFilter } = props

  const Component = filterComponents[type]

  if (!Component) return null

  async function handleSave() {
    await submitForms()
    closeFilter()
  }
  const classes = classNames('mobile-filter__list', {
    'mobile-filter__list--visible': isVisible,
  })

  return (
    <div className={classes}>
      <div className="mobile-filter__list-header">
        <span>{title}</span>
      </div>
      <ul>
        <Component {...props} />
        <div className="mobile-filter__list-footer text-center p-3">
          <Button variant="primary" onClick={handleSave}>
            {t('MOBILE_FILTER_SAVE')}
          </Button>
        </div>
      </ul>
    </div>
  )
}
